<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import priceDimension from './components/index';
import CompanyList from './components/company_list.vue';
// import ProductList from './components/product_list.vue';

const configList = [
  {
    code: 'customerCode',
    restful: '/mdm/mdmCustomerMsgController/select',
    restfulParams: {
      enableStatus: '009',
    },
    filterable: true,
    remote: true,
    remoteParams: 'customerName',
    optionsKey: {
      label: 'customerName',
      value: 'customerCode',
    },
  },
  {
    code: 'productCode',
    restful: '/mdm/mdmProductController/productSelectList',
    restfulParams: {
      enableStatus: '009',
    },
    filterable: true,
    remote: true,
    remoteParams: 'productName',
    optionsKey: {
      label: 'productName',
      value: 'productCode',
    },
  },
  {
    code: 'orgCode',
    restful: '/mdm/baseTreeController/orgTree',
    restfulParams: {
      enableStatus: '009',
    },
    type: 'FormSelectTree',
    props: {
      props: {
        parent: 'parentCode', // 父级唯一标识
        value: 'code', // 唯一标识
        label: 'name', // 标签显示
        children: 'children', // 子级
      },
    },
  },
  {
    code: 'channel',
    dictCode: 'customer_source',
    optionsKey: {
      label: 'dictValue',
      value: 'dictCode',
    },
  },
  {
    code: 'priceGroup',
    dictCode: 'price_group',
    optionsKey: {
      label: 'dictValue',
      value: 'dictCode',
    },
  },
  {
    code: 'terminalCode',
    restful: '/mdm/mdmTerminalController/select',
    filterable: true,
    remote: true,
    remoteParams: 'terminalName',
    headers: { functionCode: 'select-terminal' },
    restfulParams: {
      enableStatus: '009',
    },
    optionsKey: {
      label: 'terminalName',
      value: 'terminalCode',
    },
  },
];
formCreate.component('priceDimension', priceDimension);
formCreate.component('CompanyList', CompanyList);
formCreate.component('ProductList', CompanyList);
export default {
  extends: Form,
  data() {
    return {
      productInfo: null,
      rule: [],
      rule1: [],
      rule2: [],
      ruleConfig: [],
      buttonText: {
        // submit: '查询价格记录',
        close: '关闭',
        submit: '提交',
        // submitAudit: '提交',
      },
      buttons: {
        submit: true,
        close: true,
        loading: false,
        // submitAudit: true,
      },
      formExt: {
        customerName: '',
        productName: '',
        productCode: '',
        ext1: '',
        ext2: '',
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    await this.getFormRule('mdm_price_maintain_form');

    await this.rule.forEach((v) => {
      // else if (rowData.field === 'conditionGroupCode') {   // 干掉
      //   rowData.restful = '/mdm/mdmPriceConditionTypeController/groupByConditionType';
      //   rowData.headers = { functionCode: 'select-conditional-field-classification' };
      //   rowData.refresh = true;
      //   rowData.restfulParamsGetValue = { conditionTypeCode: 'conditionTypeCode' };
      //   rowData.optionsKey = {
      //     label: 'conditionGroupName',
      //     value: 'conditionGroupCode',
      //   };
      //   rowData.on.change = (val) => {
      //     this.conditionChange(rowData.options, val);
      //   };
      //   this.rule1.push(rowData);
      // }
      const rowData = v;
      // if (rowData.field === 'conditionTypeCode') {
      //   rowData.restful = '/mdm/mdmPriceConditionTypeController/priceConditionTypeSelectList';
      //   rowData.headers = { functionCode: 'select-conditions' };
      //   rowData.optionsKey = {
      //     label: 'conditionTypeDesc',
      //     value: 'conditionTypeCode',
      //   };
      //   this.rule1.push(rowData);
      //   rowData.on.change = () => {
      //     // this.getRule('conditionGroupDesc').props.disabled = false;
      //   };
      // } else
      if (rowData.field === 'conditionGroupName') {
        rowData.props.disabled = true;
        rowData.value = '公司主体/产品/金额';
        this.rule1.push(rowData);
      } else if (rowData.field === 'conditionGroupCode') {
        rowData.props.disabled = true;
        if (this.formConfig.code === 'add') {
          rowData.value = 'S004';
        }
        this.rule1.push(rowData);
      } else if (rowData.field === 'ext2') {
        rowData.props = {
          ...rowData.props,
          params: {
            functionCode: 'company_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: rowData.title,
        };
        rowData.on = {
          change: (e) => {
            if (e && e.length > 0) {
              this.setValue({
                ext2: e[0].companyName,
                ext1: e[0].companyCode,
              });
              this.formExt.ext2 = e[0].companyName;
              this.formExt.ext1 = e[0].companyCode;
            } else {
              this.setValue({
                ext2: null,
                ext1: null,
              });
              this.formExt.ext2 = null;
              this.formExt.ext1 = null;
            }
          },
        };
        this.rule1.push(rowData);
      } else if (rowData.field === 'productName') { // 商品名称
        rowData.props = {
          ...rowData.props,
          params: {
            functionCode: 'product_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: rowData.title,
        };
        rowData.on = {
          change: (e) => {
            if (e && e.length > 0) {
              this.formExt.productCode = e[0].materialCode;
              this.formExt.productName = e[0].materialName;
              this.setValue({
                productCode: e[0].materialCode,
                productName: e[0].materialName,
              });
              this.getTypeInfo(e[0].productCode);
            } else {
              this.setValue({
                productCode: null,
                productName: null,
              });
              this.formExt.productCode = null;
              this.formExt.productName = null;
            }
          },
        };
        this.rule1.push(rowData);
      } else if (rowData.field === 'ext4') { // 金额
        this.rule1.push(rowData);
      } else if (rowData.field === 'unitType') {
        // rowData.restful = '/mdm/mdmProductController/findProductUnit';
        // rowData.optionsKey = {
        //   label: 'unitTypeName',
        //   value: 'unitType',
        // };

        // rowData.restfulParamsGetValue = {
        //   productCode: productCode.value,
        // };
        rowData.on.change = (val) => {
          this.unitChange(val);
        };
        this.rule2.push(rowData);
      } else if (rowData.field === 'priceName') {
        rowData.props.disabled = true;
        this.rule2.push(rowData);
      } else if (
        rowData.field === 'price'
        || rowData.field === 'priceUnit'
        || rowData.field === 'currencyType'
      ) {
        if (rowData.field === 'price') {
          rowData.validate = [
            ...rowData.validate,
            {
              trigger: 'blur',
              pattern: /^\d+(\.\d+)?$/,
              message: '仅能输入数字',
            },
          ];
        }
        this.rule2.push(rowData);
      } else if (rowData.field === 'beginDate' || rowData.field === 'endDate') {
        rowData.props = {
          ...rowData.props,
          relation: {
            beginField: 'beginDate',
            endField: 'endDate',
            gtNow: true,
            isEq: true,
          },
        };
        this.rule2.push(rowData);
      }
    });

    if (this.formConfig.selection[0]) {
      this.getData();
    } else {
      this.rule = this.rule1.concat(this.rule2);
      // this.rule.push({
      //   field: 'priceDimension',
      //   type: 'priceDimension',
      //   props: {
      //     disabled: false,
      //     col: {
      //       md: { span: 24 },
      //     },
      //   },
      // });
    }
  },
  methods: {
    // 获取单位类型
    getTypeInfo(code) {
      request.post('/mdm/mdmProductController/findProductUnit', {
        productCode: code,
      }).then((res) => {
        const tempArr = [];
        res.result.forEach((resItem) => {
          tempArr.push({
            label: item.unitTypeName,
            value: item.unitType,
          });
        });
        const productName = this.getRule('productName');
        productName.options = tempArr;
      });
    },
    // 动态控件改变
    dynamicChange(fieldCode, value, options) {
      if (fieldCode === 'productCode') {
        this.productInfo = {
          ...options.filter((v) => v.productCode === value)[0],
        };
      }
    },
    // 选择单位类型
    unitChange(val) {
      this.setValue({ priceName: this.productInfo[`${val}Name`] });
      this.setValue({ priceUnit: this.productInfo[val] });
    },
    async priceConditionConfig(ruleConfig, data) {
      const rule = [];
      await ruleConfig.forEach((v) => {
        let rowData = v;
        console.log(configList);
        const list = configList.filter((a) => a.code === rowData.fieldCode)[0];
        if (v.fieldCode !== 'orgCode') {
          rowData = {
            type: 'select',
            field: v.fieldCode,
            title: v.fieldName,
            refresh: true,
            restful: list ? list.restful : '',
            restfulParams: list ? list.restfulParams : '',
            dictCode: list ? list.dictCode : '',
            optionsKey: list ? list.optionsKey : '',
            headers: list ? list.headers : undefined,
            props: {
              ...rowData.props,
              filterable: list.filterable || false,
              remote: list.remote || false,
              remoteParams: list.remoteParams || '',
            },
            on: {
              change: (value) => {
                this.dynamicChange(v.fieldCode, value, rowData.options);
              },
            },
            col: {
              md: { span: 8 },
            },
            value: '',
          };
        } else {
          rowData = {
            type: 'FormSelectTree',
            field: v.fieldCode,
            title: v.fieldName,
            refresh: true,
            restful: list ? list.restful : '',
            restfulParams: list ? list.restfulParams : '',
            headers: { functionCode: 'select-org' },
            props: {
              ...rowData.props,
              props: {
                parent: 'parentCode', // 父级唯一标识
                value: 'code', // 唯一标识
                label: 'name', // 标签显示
                children: 'children', // 子级
              },
            },
            on: {
              change: (value) => {
                this.dynamicChange(v.fieldCode, value, rowData.options);
              },
            },
            col: {
              md: { span: 8 },
            },
            value: '',
          };
        }
        if (v.fieldCode === 'customerCode') {
          rowData.on = {
            ...rowData.on,
            getLabel: (e) => {
              this.formExt.customerName = e;
            },
          };
        }
        if (v.fieldCode === 'productCode') {
          rowData.on = {
            ...rowData.on,
            getLabel: (e) => {
              this.formExt.productName = e;
            },
          };
        }
        rule.push(rowData);
      });
      this.rule = this.rule1.concat(rule).concat(this.rule2);
      // this.rule.push({
      //   field: 'priceDimension',
      //   type: 'priceDimension',
      //   props: {
      //     disabled: false,
      //     col: {
      //       md: { span: 24 },
      //     },
      //   },
      // });
      this.reload(this.rule);
      if (this.formConfig.selection[0]) {
        this.rule.forEach((item) => {
          const rowData = item;
          if (item.field !== 'beginDate' && item.field !== 'endDate') {
            rowData.props.disabled = true;
          }
          return rowData;
        });
      }
      if (data) {
        this.setValue({ ...data, conditionGroupCodeShow: data.conditionGroupCode });
      }
    },
    // 条件字段描述时间
    conditionChange(options, val) {
      options.forEach((v) => {
        if (v.conditionGroupCode === val) {
          this.ruleConfig = v.mdmPriceConditionGroupRelFieldRespVos || [];
          this.setValue({ conditionGroupDesc: v.conditionGroupName });
          this.setValue({ conditionGroupCodeShow: v.conditionGroupCode });
        }
      });
      this.priceConditionConfig(this.ruleConfig);
    },
    // 获取表单数据
    getData() {
      request
        .get('/mdm/mdmPriceSettingController/query', {
          id: this.formConfig.selection[0],
        })
        .then((res) => {
          const data = {
            ...res.result,
          };
          this.priceConditionConfig(data.mdmPriceConditionGroupRelFieldRespVos, data);
        });
    },
    // 提交
    async submit(type) {
      let params = this.getFormData();
      if (params) {
        if (this.formConfig.code === 'add') {
          params.conditionGroupCode = 'S004';
          params.conditionGroupName = '公司主体/产品/金额';
          delete params.conditionGroupCodeShow;
          params.conditionTypeCode = 'P001';
          params.ext10 = '1';
          params.ext3 = 1;
          params.ext37 = 99999999;
        }
        let path = '';
        if (this.formConfig.selection[0]) {
          path = '/mdm/mdmPriceSettingController/update';
          params = { ...params, id: this.formConfig.selection[0] };
        } else {
          path = '/mdm/mdmPriceSettingController/save';
        }
        params = { ...params, ...this.formExt };
        request.post(path, params).then((result) => {
          if (result.success) {
            this.$message.success('保存成功');
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
    // // 编辑赋值
    // initEditValue() {
    //   const submitType = this.formConfig.type;
    //   if (submitType === 'add') {
    //     this.resetFields();
    //   } else if (this.formConfig.row) {
    //     request
    //       .post('/mdm/mdmfunctionsub/query', { functionCode: this.formConfig.row })
    //       .then((res) => {
    //         this.setValue(res.result);
    //       });
    //   }
    // },
  },
};
</script>
