var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("上次更新价格"),
      ]),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("定价记录号：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.priceSettingCode) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("条件类型：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.conditionTypeDesc) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("条件字段分类：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.conditionGroupDesc) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("客户：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.customerName) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("商品：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.productName) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("价格：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.price) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("币种：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.currencyTypeName) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("单位类型：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.unitType) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("价格单位：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.priceName) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("有效期开始时间：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.beginDateTime) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("有效期截止时间：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.endDateTime) || "")),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("生效状态：")]),
            _vm._v(_vm._s((_vm.value && _vm.value.enableStatusName) || "")),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }