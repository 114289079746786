<script>
import TablePage from '../../../../../components/table_page';
import Form from '../form';
// import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  props: {
    parentCode: String,
  },
  data() {
    return {
      requestUrl: '',
      formConfig: {},
      selection: [],
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },

  created() {
    this.getConfigList('mpm_price_maintain_table');
  },
  methods: {
    clickVisible({ val, row }) {
      if (row.ext10 === '1') {
        return true;
      }
      return false;
    },
    modalClick({ val, row }) {
      console.log(val);
      if (val.code === 'edit') {
        this.formConfig = { ...val, row: { ...row }, code: val.code };
        this.formConfig.selection = [row.id];
        this.modalConfig.title = '编辑';
        this.openModal();
      } else if (val.code === 'add') {
        this.formConfig = { ...val, row: { ...row }, code: val.code };
        this.formConfig.selection = [];
        this.modalConfig.title = '新增';
        this.openModal();
      } else if (val.code === 'view') {
        this.formConfig = { ...val, row: { ...row }, code: val.code };
        this.formConfig.selection = [row.id];
        this.modalConfig.title = '查看';
        this.openModal();
      }
      return true;
    },
  },
};
</script>
