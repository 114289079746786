<template>
  <div>
    <el-divider content-position="left">上次更新价格</el-divider>
    <el-row>
      <el-col :span="8"><span>定价记录号：</span>{{ value&& value.priceSettingCode || '' }}</el-col>
      <el-col :span="8"><span>条件类型：</span>{{ value&& value.conditionTypeDesc ||''}}</el-col>
      <el-col :span="8"><span>条件字段分类：</span>{{ value&& value.conditionGroupDesc ||''}}</el-col>
      <el-col :span="8"><span>客户：</span>{{ value&& value.customerName ||""}}</el-col>
      <el-col :span="8"><span>商品：</span>{{ value&& value.productName ||''}}</el-col>
      <el-col :span="8"><span>价格：</span>{{ value&& value.price ||''}}</el-col>
      <el-col :span="8"><span>币种：</span>{{ value&& value.currencyTypeName ||''}}</el-col>
      <el-col :span="8"><span>单位类型：</span>{{ value&& value.unitType ||''}}</el-col>
      <el-col :span="8"><span>价格单位：</span>{{ value&& value.priceName ||''}}</el-col>
      <el-col :span="8"><span>有效期开始时间：</span>{{ value&& value.beginDateTime ||""}}</el-col>
      <el-col :span="8"><span>有效期截止时间：</span>{{ value&& value.endDateTime ||''}}</el-col>
      <el-col :span="8"><span>生效状态：</span>{{ value&& value.enableStatusName ||''}}</el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'priceDimension',
  components: {
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          priceSettingCode: '1',
          conditionTypeDesc: '',
          conditionGroupDesc: '',
          customerName: '',
          productName: '',
          price: '',
          currencyTypeName: '',
          unitType: '',
          priceName: '',
          beginDateTime: '',
          endDateTime: '',
          enableStatusName: '',
        };
      },
    },
  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
};
</script>

<style lang="less" scoped></style>
